import Store from "@/services/store";
import { nationalities } from "../../../checkin-facility/utils/mockup";
export default {
   components: {},
   data() {
      return {
         data: {
            user: {},
            is_custom: 0,
            facility_settings: {
               etl_pull_request: {},
            },
            receive_checkin_email: 0,
         },
         types: [
            { id: 0, value: "N/A" },
            { id: "ohip", value: "Ohip" },
            { id: "staysee", value: "Staysee" },
         ],
         statuses: [
            { id: 0, name: "Inactive" },
            { id: 1, name: "Active" },
         ],
         models: {},
         errors: {},
         nationalities: nationalities,
      };
   },
   async mounted() {},
   methods: {
      async submit() {
         let data = {
            ...this.data,
            facility_settings: [],
         };
         let facility_settings = [];
         if (data.is_custom) {
            data.facility_settings.push({
               name: "package",
               value: "custom",
            });
            data.facility_settings.push({
               name: "pms_settings",
               value: this.data.facility_settings.pms_settings,
            });
            if (["ohip", "staysee"].includes(this.data.facility_settings.etl_pull_request.type)) {
               data.etl_pull_request = {
                  ...this.data.facility_settings.etl_pull_request,
               };
               data.etl_pull_request.credentials = JSON.stringify(data.etl_pull_request.credentials);
            }
         }
         await this.$lionheart
            .post("v2/dashboard/facilities", data)
            .then((success) => {
               this.data = {
                  user: {},
                  is_custom: 0,
                  facility_settings: {
                     etl_pull_request: {},
                  },
                  receive_checkin_email: 0,
               };

               this.errors = {};
               this.$bvModal.hide("form");
               alert("Record successfully created.");
            })
            .catch((error) => {
               this.errors = error.response.data.errors;
               Store.commit("checkin-site/loading", false);

               console.log(this.errors);
            });
      },
      async is_custom($event) {
         if (!this.data.id) {
            this.data.facility_settings = {
               pms_settings: {},
               etl_pull_request: {
                  settings: {},
                  credentials: {},
               },
            };
         }
         if ($event.target.checked) {
            this.data.facility_settings = {
               package: "custom",
               pms_settings: {},
               etl_pull_request: {
                  settings: {},
                  credentials: {},
               },
            };
         }
      },
      async init() {
         this.$bvModal.show("form");
      },
   },
};
