<template>
   <b-modal size="md" :id="id" centered>
      <div>{{ message || $t("confirmation_modal.are_you_sure") }}</div>
      <div><slot></slot></div>
      <template #modal-footer>
         <div class="mx-n1">
            <button class="btn btn-outline-danger btn-w-md mx-1" @click="$bvModal.hide(id)">
               {{ cancel || $t("confirmation_modal.cancel") }}
            </button>
            <button class="btn btn-primary btn-w-md mx-1" @click="confirm()">
               {{ okay || $t("confirmation_modal.confirm") }}
            </button>
         </div>
      </template>
   </b-modal>
</template>

<script>
export default {
   props: {
      id: { type: String, default: "modal-confirmation" },
      message: { type: String },
      cancel: { type: String },
      okay: { type: String },
   },
   methods: {
      confirm() {
         this.$bvModal.hide(this.id);
         this.$emit("click-handler", true);
      },
   },
};
</script>
