import FormComponent from "./form.component.vue";
import Confirmation from "../../components/confirmation-modal.component.vue";
export default {
   components: {
      FormComponent,
      Confirmation,
   },
   data() {
      return {
         data: {
            current_page: 1,
            per_page: 10,
            total: 10,
         },
         datum: {
            id: null,
         },
         force_delete: 0,
      };
   },
   async mounted() {
      await this.list({ page: 1, per_page: this.data.per_page });
   },
   methods: {
      async login(email) {
         let login = await this.$lionheart.post("v2/auth/login/email", { email: email }).then((response) => response.data);

         if (login.success) {
            await this.$storage.set("auth", login.data);
            location.href = "/dashboard/home";
         }
      },
      async confirm_deletion($event) {
         if (typeof $event == "boolean") {
            await this.$lionheart
               .delete("v2/dashboard/facilities/" + this.datum.id)
               .then(async (success) => {
                  this.datum.id = null;
                  await this.list({ page: 1, per_page: this.data.per_page });
                  this.force_delete = 0;
               })
               .catch((error) => {});
         }
      },
      async remove($event) {
         this.datum.id = $event;
         this.$bvModal.show("modal-confirmation");
      },
      async paginate(page) {
         var filter = {
            page: page,
         };
         await this.list(filter);
      },

      async list(data) {
         var response = await this.$lionheart.get("dashboard/facilities", { params: data });
         this.data = response.data;

         console.log(this.data);
      },
   },
};
